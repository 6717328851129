import React from "react"
import Layout from "../components/layout"
import { Col, Container, Row } from "reactstrap"
import Header from "../components/header"
import SEO from "../components/SEO/seo"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/LinkResolver"
import { RichText } from "prismic-reactjs"
import AppendicesHtmlSerializer from "../utils/AppendicesHtmlSerializer"

const AppendixPage = ({ data: { prismicAppendix } }) => {
  return (
    <Layout>
      <SEO
        title={prismicAppendix.data.seotitle}
        desc={prismicAppendix.data.seodescription}
      />
      <Header title={prismicAppendix.data.title}/>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg="9">
              <div className="card shadow border-0 rounded">
                <div className="card-body">
                  <RichText render={prismicAppendix.data.content.raw} htmlSerializer={AppendicesHtmlSerializer}/>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

AppendixPage.propTypes = {
  data: PropTypes.shape({
    prismicAppendix: PropTypes.object.isRequired
  }).isRequired
}

export default withPrismicPreview(AppendixPage, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY_NAME,
    linkResolver
  }
])

export const pageQuery = graphql`
  query AppendixQuery($id: String) {
    prismicAppendix(id: { eq: $id }) {
      _previewable
      uid
      data {
        content {
          raw
        }
        seodescription
        seotitle
        title
      }
    }
  }
`
