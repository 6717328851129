import React, { useEffect, useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Container } from "reactstrap"
import { GatsbyImage } from "gatsby-plugin-image"


export default function Topbar() {

  const { features, logo, menu } = useStaticQuery(query)


  const menu1 = []
  menu.data.nav.map((item1, key1) => {
    if (item1.primary.sub_menu.document !== null) {
      let menu2 = []
      item1.primary.sub_menu.document.data.nav.map((item2, key2) => {
        let menu3 = []
        item2.items.map((item3, key3) => {
          if (item3.sub_nav_label != null) {
            menu3.push({ title: item3.sub_nav_label, link: item3.sub_nav_link.url, isNewPage: item3.isnew })
          }
        })
        if (menu3.length > 0) {
          menu2.push({
            id: key2,
            title: item2.primary.label,
            link: "/#",
            isOpenNestedSubMenu: false,
            isNew: item2.primary.isnew,
            nestedChild: menu3
          })
        } else {
          menu2.push({
            id: key2,
            title: item2.primary.label,
            link: item2.primary.link.url
          })
        }
      })
      if (menu2.length > 0) {
        menu1.push({ id: key1, title: item1.primary.label, link: "/#", isOpenSubMenu: false, child: menu2 })
      } else {
        menu1.push({ id: key1, title: item1.primary.label, link: "/#" })
      }
    } else {
      menu1.push({ id: key1, title: item1.primary.label, link: item1.primary.link.url })
    }
  })

  const [opened, setOpened] = useState(false)
  const [refreshed, setRefreshed] = useState(false)
  const [navLinks, setNavLinks] = useState(
    menu1
  )

  function toggleLine() {
    setOpened(!opened)
  }

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("top-menu")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname && items[i].pathname !== "/") {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  function activateParentDropdown(item) {
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent1 = parent.parentElement
      parent1.classList.add("active") // li
      if (parent1) {
        const parent2 = parent1.parentElement
        parent2.classList.add("active") // li
        if (parent2) {
          const parent3 = parent2.parentElement
          parent3.classList.add("active") // li
          if (parent3) {
            const parent4 = parent3.parentElement
            parent4.classList.add("active") // li
          }
        }
      }
    }
  }

  function openBlock(level2_id) {
    var tmpLinks = navLinks
    navLinks.map(tmpLink =>
      //Match level 2 id
      tmpLink.id === level2_id
        ? (tmpLink.isOpenSubMenu = !tmpLink.isOpenSubMenu)
        : false
    )
    setNavLinks(tmpLinks)
    setRefreshed(!refreshed)
  }

  function openNestedBlock(level2_id, level3_id) {
    var tmpLinks = navLinks
    tmpLinks.map(tmpLink =>
      //Match level 2 id
      tmpLink.id === level2_id
        ? tmpLink.child.map(tmpchild =>
          //if level1 id is matched then match level 3 id
          tmpchild.id === level3_id
            ? //if id is matched then update status(level 3 sub menu will be open)
            (tmpchild.isOpenNestedSubMenu = !tmpchild.isOpenNestedSubMenu)
            : (tmpchild.isOpenNestedSubMenu = false)
        )
        : false
    )
    setNavLinks(tmpLinks)
    setRefreshed(!refreshed)
  }


  return (
    <header id="topnav" className="defaultscroll sticky">
      <Container>
        <div>
          <Link className="logo" to="/">
            <GatsbyImage
              image={logo.childImageSharp.gatsbyImageData}
              id="brandLogo"
              alt="Logiciel de comptabilité pour entrepreneurs"/>
          </Link>
        </div>
        <div className="buy-button">
          <a
            href="https://app.bizyness.fr/#/login"
            id="buyButton"
            className="btn btn-primary"
          >
            Se Connecter
          </a>
        </div>
        <div className="menu-extras">
          <div className="menu-item">
            <Link
              to="#"
              onClick={toggleLine}
              className={
                opened ? "navbar-toggle open" : "navbar-toggle"
              }
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Link>
          </div>
        </div>

        <div
          id="navigation"
          style={{ display: opened ? "block" : "none" }}
        >
          <ul className="navigation-menu" id="top-menu">
            {navLinks.map((navLink, key) =>
              navLink.child ? (
                <li className="has-submenu" key={key}>
                  {/* child item(menu Item) - Level 1 */}
                  <Link
                    to={navLink.link}
                    onClick={event => {
                      event.preventDefault()
                      openBlock(navLink.id)
                    }}
                  >
                    {navLink.title}
                  </Link>
                  <span className="menu-arrow"></span>
                  {navLink.isMegaMenu ? (
                    // if menu is mega menu(2 columns grid)
                    <ul
                      className={
                        navLink.isOpenSubMenu
                          ? "submenu megamenu open"
                          : "submenu megamenu"
                      }
                    >
                      <li>
                        <ul>
                          {navLink.child.map((item, childKey) =>
                            item.id < 12 ? (
                              <li key={childKey}>
                                <Link to={item.link}>{item.title}</Link>
                              </li>
                            ) : null
                          )}
                        </ul>
                      </li>
                      <li>
                        <ul>
                          {navLink.child.map((item, childKey) =>
                            item.id < 22 && item.id > 11 ? (
                              <li key={childKey}>
                                <Link to={item.link}>
                                  {item.title}
                                  {item.isNew ? (
                                    <span className="badge badge-danger rounded ml-2">
                                        beta
                                      </span>
                                  ) : null}
                                </Link>
                              </li>
                            ) : null
                          )}
                        </ul>
                      </li>
                      <li>
                        <ul>
                          {navLink.child.map((item, childKey) =>
                            item.id > 21 ? (
                              <li key={childKey}>
                                <Link to={item.link}>
                                  {item.title}
                                  {item.isOnePage ? (
                                    <span className="badge badge-warning rounded ml-2">
                                        Onepage
                                      </span>
                                  ) : null}
                                </Link>
                              </li>
                            ) : null
                          )}
                        </ul>
                      </li>
                    </ul>
                  ) : (
                    // if menu is not mega menu(1grid)
                    <ul
                      className={
                        navLink.isOpenSubMenu ? "submenu open" : "submenu"
                      }
                    >
                      {navLink.child.map((childArray, childKey) =>
                        childArray.nestedChild ? (
                          // sub menu item - Level 2
                          <li className="has-submenu" key={childKey}>
                            <Link
                              to={childArray.link}
                              onClick={event => {
                                event.preventDefault()
                                openNestedBlock(
                                  navLink.id,
                                  childArray.id
                                )
                              }}
                            >
                              {" "}
                              {childArray.title}{" "}
                              {childArray.isNew ? (
                                <span className="badge badge-danger rounded">
                                    beta
                                  </span>
                              ) : null}
                            </Link>
                            <span className="submenu-arrow"></span>
                            <ul
                              className={
                                childArray.isOpenNestedSubMenu
                                  ? "submenu open"
                                  : "submenu"
                              }
                            >
                              {childArray.nestedChild.map(
                                (nestedChildArray, nestedKey) => (
                                  // nested sub menu item - Level 3
                                  <li key={nestedKey}>
                                    <Link to={nestedChildArray.link}>
                                      {nestedChildArray.title}{" "}
                                      {nestedChildArray.isNewPage ? (
                                        <span className="badge badge-primary rounded">
                                            NOUVEAU
                                          </span>
                                      ) : null}
                                    </Link>
                                  </li>
                                )
                              )}
                            </ul>
                          </li>
                        ) : (
                          <li key={childKey}>
                            <Link to={childArray.link}>
                              {childArray.title}
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                  )}
                </li>
              ) : (
                <li key={key}>
                  <Link to={navLink.link}>{navLink.title}</Link>
                </li>
              )
            )}
          </ul>
          <div className="buy-menu-btn d-none">
            <a
              href="https://app.bizyness.fr/#/login"
              className="btn btn-primary"
            >
              Se Connecter
            </a>
          </div>
        </div>
      </Container>
    </header>
  )
}

const query = graphql`
  query Topbar {
    logo: file(relativePath: {eq: "logo-dark.png"}) {
      childImageSharp {
        gatsbyImageData(width: 168, quality: 90, placeholder: NONE, layout: FIXED)
      }
    }
    menu: prismicMenu(data: {name: {eq: "Header"}}) {
      data {
        nav {
          ... on PrismicMenuDataNavNavItem {
            id
            primary {
              label
              link {
                url
              }
              sub_menu {
                document {
                  ... on PrismicSubMenu {
                    id
                    data {
                      name
                      nav {
                        ... on PrismicSubMenuDataNavNavItem {
                          id
                          items {
                            sub_nav_label
                            sub_nav_link {
                              url
                            }
                            isnew
                          }
                          primary {
                            label
                            link {
                              url
                            }
                            isnew
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicPage {
                    id
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`