/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import PropTypes from "prop-types"
import React, { useEffect } from "react"

import "../assets/styles/index.scss"
import Topbar from "./topbar"
import Footer from "./footer"
import { getAffiliateId, setAffiliateId, setCoupons, setTags } from "../assets/javascript/affiliate"


const Layout = ({ children }) => {

  useEffect(() => {
    document.body.classList = ""
    window.addEventListener("scroll", scrollNavigation, true)

    var featureBox = document.getElementsByClassName("features")
    for (var i = 0; i < featureBox.length; i++) {
      featureBox[i].classList.remove("mt-5")
    }

    var trackingParam = {
      referral_code: getAffiliateId()
    }

    window.tap("click", trackingParam)

    setTags()
    setAffiliateId()
    setCoupons();

    return function componentWillUnmount() {
      window.removeEventListener("scroll", scrollNavigation, true)
    }
  })



  function scrollNavigation() {
    var doc = document.documentElement
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
    if (top > 80) {
      document.getElementById("topnav").classList.add("nav-sticky")
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky")
    }
  }

  return (
    <>
      <Topbar/>
      {children}
      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
