import React from "react"
import { Col, Container, Row } from "reactstrap"
import PropTypes from "prop-types"

const Header = ({ title, subtitle, isBig }) => {
  return (
    <>
      <section className="bg-half bg-light d-table w-100">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12" className="text-center">
              <div className="page-next-level">
                <h1 className={isBig ? "mb-4 big-font" : "title"}>{title}</h1>
                {isBig && (
                  <h2 className="text-muted mx-auto mb-0 big-text subheading">{subtitle}</h2>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
    </>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isBig: PropTypes.bool
}

export default Header
